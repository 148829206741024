<template>
  <v-container fluid class="primary1 fill-height">
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="6" lg="4" xl="3" class="white--text">
        <v-card color="primary1" dark flat tile>
          <v-window v-model="onboarding">
            <v-window-item v-for="n in length" :key="`card-${n}`">
              <div>What is your main fitness goal?</div>

              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="12" sm="6">
                  <v-card color="red" height="200"> </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card color="red" height="200"> </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card color="red" height="200"> </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card color="red" height="200"> </v-card>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>

          <v-card-actions class="justify-space-between">
            <v-btn text @click="prev">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-item-group v-model="onboarding" class="text-center" mandatory>
              <v-item
                v-for="n in length"
                :key="`btn-${n}`"
                v-slot="{ active, toggle }"
              >
                <v-btn :input-value="active" icon @click="toggle">
                  <v-icon>mdi-record</v-icon>
                </v-btn>
              </v-item>
            </v-item-group>
            <v-btn text @click="next">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      length: 3,
      onboarding: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    next() {
      this.onboarding =
        this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
    },
    download() {
      // console.log(("download app");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
</style>

